.tabs {
  display: flex;
  padding-top: 16px;
  align-items: center;

}
.tab-item {
  border-bottom: 1px solid var(--border-color);
  position: relative;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /*padding-bottom: 16px;*/
  height: 50px;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  color: var(--muted-color);
}
.tab-item.active {
  color: var(--text-color);
}
.tab-item.active:after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  height: 1px;
  background-color: var(--text-color);
  width: 100%;
}
