.course {
  position: relative;
}

.course-content-wrap {
  /*min-height: calc(100vh - 91px);*/
  position: relative;
  margin-left: 541px;
  max-width: 1214px;
  /*padding-right: 40px;*/
  min-height: 400px;
}

html[dir='rtl'] .course-content-wrap {
  margin-left: 0;
  margin-right: 541px;
}

.content-wrap {
  padding-top: 24px;
}

.content-wrap .empty-list {
  text-align: left;
}

html[dir='rtl'] .content-wrap .empty-list {
  text-align: right;
}

.course-content {
  padding-right: 40px;
  width: 100%;
  padding-top: 24px;
  /*margin: 0 auto;*/
  /*min-height: calc(100vh - 91px);*/
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

html[dir='rtl'] .course-content {
  padding-right: 0;
  padding-left: 40px;
}

.course-content-left {
  width: 700px;
  max-width: 700px;
  min-width: 700px;
  padding-bottom: 32px;
}

.course-content-right {
  padding-left: 64px;
  width: 464px;
  min-width: 464px;
  position: relative;
  padding-bottom: 32px;
  top: 0;
}

html[dir='rtl'] .course-content-right {
  padding-left: 0;
  padding-right: 64px;
}

.course-content-right-sticky {
  position: sticky;
  top: 114px;
  margin-bottom: 32px;
}

.course-content-wrap .breadcrumbs {
  position: fixed;
  top: 33px;
  z-index: 6;
  margin-left: -16px;
}

html[dir='rtl'] .course-content-wrap .breadcrumbs {
  margin-left: 0;
  margin-right: -16px;
}

.course-content-left .material-type-title {
  font-size: 14px;
}

.course-content .title {
  padding-bottom: 12px;
}

.course-content .title-32 {
  padding-left: 24px;
  margin-top: 8px;
  position: relative;
  text-transform: capitalize;
  border-left: 1px solid var(--border-color);
}

html[dir='rtl'] .course-content .title-32 {
  border-left: none;
  border-right: 1px solid var(--border-color);
  padding-left: 0;
  padding-right: 24px;
}

.course-about p {
  font-size: 18px;
  line-height: 27px;
}

.course-about {
  padding-right: 40px;
  overflow: hidden;
  padding-top: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*padding-bottom: 44px;*/
  position: relative;
}

html[dir='rtl'] .course-about {
  padding-right: 0;
  padding-left: 40px;
}

.course-about .see-material-btn {
  margin-top: 32px;
}

.course-about-stream-name {
  color: var(--muted-color);
}

.course-about .date-info {
  padding-bottom: 16px;
  padding-top: 24px;
  text-align: left;
}

html[dir='rtl'] .course-about .date-info {
  text-align: right;
}

.course-about .timer {
  border-top: 1px solid var(--border-color);
}

.course-about-star {
  position: absolute;
  left: 300px;
  top: -100px;
  z-index: -1;
  pointer-events: none;
}

html[dir='rtl'] .course-about-star {
  left: auto;
  right: 300px;
}

.course-content-numeric {
  width: 64px;
  min-width: 64px;
  font-size: 24px;
  text-align: center;
  padding-top: 42px;
  position: absolute;
}

.course-content-padder {
  padding-left: 64px;
}

html[dir='rtl'] .course-content-padder {
  padding-left: 0;
  padding-right: 64px;
}

.content-passed-btns {
  max-width: 260px;
  padding: 32px 0 0;
}

.content-passed-btns .btn {
  min-width: 260px;
}

.content-passed-btns .btn svg {
  position: absolute;
  left: 16px;
}

html[dir='rtl'] .content-passed-btns .btn svg {
  left: auto;
  right: 16px;
}

.content-passed-btns .btn path {
  stroke: var(--text-color);
}

.next-material {
  /*max-width: 100%;*/
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  border-top: 1px solid var(--text-color);
  margin-top: 32px;
}

.course-content-right .next-material {
  margin-top: 0;
  display: none;
}

.next-material .sider-date {
  padding: 0 !important;
  margin-top: 0;
  border: none;
}

.next-material-item {
  overflow: hidden;
  color: var(--muted-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .3s ease;
}

.next-material-item:hover {
  color: var(--text-color);
}

.next-material-item-numeric, .next-material .sider-numeric {
  font-size: 24px;
  margin-right: 16px;
  min-width: 64px;
  width: 64px;
  line-height: 16px;
  text-align: center;
  border-right: 1px solid var(--muted-color);
}

html[dir='rtl'] .next-material-item-numeric, html[dir='rtl'] .next-material .sider-numeric {
  margin-right: 0;
  margin-left: 16px;
  border-right: none;
  border-left: 1px solid var(--muted-color);
}

html[dir='rtl'] .next-material-item .text-truncate {
  text-align: right;
}

.next-material-item-arrow {
  transition: all .3s ease;
  padding-left: 8px;
  transform: translateY(-3px);
  opacity: 0;
}

.next-material-item:hover .next-material-item-arrow {
  opacity: 1;
  transform: translateY(0px);
}

.course-type-task .look-chat-btn {
  min-width: 260px;
  width: 260px;
  max-width: 260px;
  margin-top: 16px;
  display: none;
}

.course-content.task-no-chat .course-content-right .course-content-right-sticky {
  display: none;
}

.course-type-task.task-no-chat .look-chat-btn {
  display: block;
}

@media only screen and (max-width: 1745px) {
  .course-content-right {
    width: 100%;
    max-width: 700px;
    min-width: auto;
  }

  .course-content-left {
    width: 100%;
    min-width: auto;
    /*min-width: auto;*/
  }

  .course-content-left .next-material {
    display: none;
  }

  .course-content-right .next-material {
    display: flex;

  }

  .next-material {
    width: calc(100% + 64px);
    margin-left: -64px;
  }

  html[dir='rtl'] .next-material {
    margin-left: 0;
    margin-right: -64px;
  }

  .course-content-right-hidden .course-content-left {
    padding-bottom: 0;
  }

  .course-content-right-sticky {
    position: relative;
    top: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .course-content-wrap .breadcrumbs {
    top: 106px;
    left: 24px;
    z-index: 1;
  }

  html[dir='rtl'] .course-content-wrap .breadcrumbs {
    top: 106px;
    left: auto;
    right: 24px;
    z-index: 1;
  }

  .breadcrumbs-link {
    max-width: 90px !important;
  }

  .course-content-wrap {
    /*margin-left: 398px;*/
    padding-left: 398px;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  html[dir='rtl'] .course-content-wrap {
    margin-right: 0;
    padding-left: 0;
    padding-right: 398px;
  }

  .course-content-left {
    max-width: 100%;
  }

  .course-content {
    padding-top: 16px;
  }

  .course-about {
    padding-top: 16px !important;
  }

  .course-about p {
    font-size: 16px;
  }

  .course-content-numeric {
    padding-top: 36px;
  }

  .next-material-item-arrow {
    opacity: 1;
  }

  .course-content-left .next-material {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .course-content {
    padding-right: 24px;
  }

  html[dir='rtl'] .course-content {
    padding-right: 0;
    padding-left: 24px;
  }

  .next-material {
    width: calc(100% + 44px);
    margin-right: 24px;
    margin-left: -44px;
  }

  html[dir='rtl'] .next-material {
    margin-right: -44px;
    margin-left: 24px;
  }

  .course-content-numeric, .next-material-item-numeric {
    width: 44px;
    min-width: 44px;
    font-size: 18px;
  }

  .course-content-padder, .course-content-right {
    padding-left: 44px;
  }

  html[dir='rtl'] .course-content-padder, html[dir='rtl'] .course-content-right {
    padding-left: 0;
    padding-right: 44px;
  }
}

@media only screen and (max-width: 768px) {
  .course-content-left {
    padding-bottom: 24px;
  }

  .course-about {
    padding: 32px 24px 0 !important;
    min-height: calc(100vh - 122px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .course-about-stream-name {
    color: var(--text-color);
    font-size: 12px;
    line-height: 14px;
  }

  .course-about .title-48 {
    font-size: 24px;
    line-height: 36px;
    padding-top: 32px !important;
    padding-bottom: 8px !important;
  }

  .course-about p {
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 8px;
  }

  .course-about .course-about-star {
    right: 32px;
    top: 16px;
    left: auto;
  }

  html[dir='rtl'] .course-about .course-about-star {
    right: auto;
    left: 32px;
  }

  .course-about .big-star svg {
    width: 160px;
    height: 160px;
  }

  .next-material-item-numeric {
    width: auto;
    min-width: auto;
    padding-right: 16px;
  }

  html[dir='rtl'] .next-material-item-numeric {
    padding-right: 0;
    padding-left: 16px;
  }

  .next-material {
    width: 100%;
    margin-right: 24px;
    margin-left: 0 !important;
  }

  .course-content-right {
    max-width: 100%;
    padding-bottom: 16px;
  }

  .course-type-task .look-chat-btn {
    display: block;
  }

  .course-type-task .course-content-right .course-content-right-sticky {
    display: none;
  }
}
