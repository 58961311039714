:root {
  --main-bg-color: #FFFFFF;
  --secondary-color: #F6F9FA;

  --text-color: #111930;
  --muted-color: #A1B7C8;
  --border-color: #E6EEF1;

  --brown-color: #DF9B69;
  --success-color: #E2F6EC;
  --error-color: #FA6D6D;
  --warning-color: #FFF885;
  --disabled-color: #D0DAE3;

  --main-gradient-bg: linear-gradient(86.01deg, var(--success-color) 30%, var(--warning-color) 165.11%);;

  --font-refular: 'gilroy-regular', sans-serif;
  --font-medium: 'gilroy-medium', sans-serif;
  --font-bold: 'gilroy-bold', sans-serif;

  --text-size: 16px;
  --text-line-height: 19px;
}
