.close-btn {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  min-width: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  transition: all .3s ease;
  padding: 0;
  outline: none;
}

.close-btn:hover, .close-btn.active {
  border-color: var(--text-color);
}

@media only screen and (max-width: 992px) {
  .close-btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
  }
}
