.breadcrumbs {
  display: flex;
}

.breadcrumbs-link {
  display: block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--muted-color);
  cursor: pointer;
  transition: all .3s ease;
}

.breadcrumbs-item {
  margin-left: 16px;
  position: relative;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
html[dir='rtl'] .breadcrumbs-item {
  margin-right: 16px;
  margin-left: 40px;
}
.breadcrumbs-item:last-child .breadcrumbs-link {
  padding-right: 0;
  color: var(--text-color);
}

.breadcrumbs-item:after {
  pointer-events: none;
  content: '';
  position: absolute;
  right: -40px;
  top: 50%;
  width: 24px;
  height: 1px;
  background-color: var(--muted-color);
  transition: all .3s ease;
}

html[dir='rtl'] .breadcrumbs-item:after {
  right: auto;
  left: -40px;
}

.breadcrumbs-item:last-child:after {
  display: none;
}

.breadcrumbs-item:hover:after, .breadcrumbs-item:hover + .breadcrumbs-item:after {
  background-color: var(--text-color);
}

.breadcrumbs-item-arrow {
  pointer-events: none;
  position: absolute;
  right: -16px;
}
html[dir='rtl'] .breadcrumbs-item-arrow {
  right: auto;
  left: -16px;
}
.breadcrumbs-item-arrow:before {
  content: '';
  opacity: 0;
  transition: all .3s ease;
  width: 1px;
  height: 8px;
  background-color: var(--text-color);
  position: absolute;
  transform: rotate(-45deg);
  border-radius: 4px;
  top: -1px;
  right: -3px;
  z-index: 1;
}

.breadcrumbs-item-arrow:after {
  content: '';
  opacity: 0;
  transition: all .3s ease;
  width: 1px;
  height: 8px;
  top: -6px;
  right: -3px;
  z-index: 1;
  background-color: var(--text-color);
  position: absolute;
  transform: rotate(45deg);
  border-radius: 4px;
}

html[dir='rtl'] .breadcrumbs-item-arrow:before, html[dir='rtl'] .breadcrumbs-item-arrow:after {
  right: auto;
  left: -3px;
  transform: rotate(-45deg);
}
html[dir='rtl'] .breadcrumbs-item-arrow:before {
  transform: rotate(45deg);
}
.breadcrumbs-item:last-child .breadcrumbs-item-arrow {
  display: none;
}

.breadcrumbs-link:hover + .breadcrumbs-item-arrow:after, .breadcrumbs-link:hover + .breadcrumbs-item-arrow:before,
.breadcrumbs-item:hover + .breadcrumbs-item .breadcrumbs-item-arrow:before, .breadcrumbs-item:hover + .breadcrumbs-item .breadcrumbs-item-arrow:after {
  opacity: 1;
}

.breadcrumbs-item:last-child {
  color: var(--text-color);
  pointer-events: none;
}

.breadcrumbs-item:last-child a,
.breadcrumbs-item:hover + .breadcrumbs-item .breadcrumbs-link,
.breadcrumbs-link:hover {
  color: var(--text-color);
}
