.content-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.material-item {
  margin: 12px 0;
}

.content-wrap.view .material-item-text {
  max-height: 100%;
}

.material-item-text, .material-item-file, .material-item-video {
  width: 100%;
  min-width: 100%;
  padding: 0 4px;
}

.material-item.material-item-file .remove-material-el {
  top: auto;
}

.material-item.material-item-file {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0;
}

.material-item-image {
  cursor: pointer;
  overflow: hidden;
  /*min-width: min(calc(25% - 8px), 150px);*/
  /*min-width: max(50%, calc(25% - 8px));*/
  min-width: calc(50% - 8px);
  max-width: 100%;
  max-height: 500px;
  margin: 4px;
}

.material-item-image img {
  float: left;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.material-item-text {
  min-width: 100%;
  max-width: 100%;
  color: rgba(17, 25, 48, .72) !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: var(--font-refular) !important;
}

.material-item-text div, .material-item-text span, .material-item-text p {
  color: rgba(17, 25, 48, .72) !important;
  font-family: var(--font-refular) !important;
  font-size: 18px !important;
  line-height: 27px !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left !important;
  background-color: transparent !important;
}

html[dir='rtl'] .material-item-text div, html[dir='rtl'] .material-item-text span, html[dir='rtl'] .material-item-text p {
  text-align: right !important;
  /*direction: ltr;*/
}

.material-item-text blockquote {
  border-left: 2px solid var(--border-color);
  padding-left: 16px;
  margin: 8px 0;
}

html[dir='rtl'] .material-item-text blockquote {
  padding-right: 16px;
  padding-left: 0;
  border-right: 2px solid var(--border-color);
  border-left: none;
}

.material-item-text ol, .material-item-text ul {
  font-size: 18px !important;
  padding-left: 16px !important;
}

html[dir='rtl'] .material-item-text ol, html[dir='rtl'] .material-item-text ul {
  padding-left: 0 !important;
  padding-right: 16px !important;
}

.material-item-text ul {
  list-style: disc;
}

.material-item-text ol, .material-item-text ul {
  color: rgba(17, 25, 48, .72) !important;
}

.material-item-text h1, .material-item-text h2, .material-item-text h3, .material-item-text h4, .material-item-text h5, .material-item-text h6 {
  color: var(--text-color);
  background-color: transparent !important;
  text-align: left !important;
  font-family: var(--font-bold) !important;
  font-size: 24px !important;
  line-height: 36px !important;
  max-width: 100% !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  font-weight: 700 !important;
  margin: 0 !important;
}

html[dir='rtl'] .material-item-text h1,
html[dir='rtl'] .material-item-text h2,
html[dir='rtl'] .material-item-text h3,
html[dir='rtl'] .material-item-text h4,
html[dir='rtl'] .material-item-text h5,
html[dir='rtl'] .material-item-text h6 {
  text-align: right !important;
}

.modal-image {
  width: auto;
  object-fit: contain;
  max-height: 100vh;
  max-width: 100%;
}

.material-item-youtube, .material-item-vimeo {
  width: 100%;
  padding: 0 4px;
  min-height: 360px;
}

.material-item-youtube > div {
  max-width: 100%;
  /*max-height: 360px;*/
  /*height: 100%;*/
}

.material-item-vimeo > div {
  max-width: 100% !important;
  height: 100% !important;
  max-height: 400px !important;
  background: #000;
}

@media only screen and (max-width: 1200px) {
  .material-item-text div {
    font-size: 16px !important;
  }

  .material-item-text h1, .material-item-text h2, .material-item-text h3, .material-item-text h4, .material-item-text h5, .material-item-text h6 {
    font-size: 20px !important;
    line-height: 28px !important;
  }

  .course-content .title-32 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 992px) {
  .material-item-image {
    /*min-width: 100%;*/
    width: 100%;
    max-width: 100%;
  }

  .material-item-text ol, .material-item-text ul, .material-item-text div, .material-item-text span, .material-item-text p {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .material-item-text h1, .material-item-text h2, .material-item-text h3, .material-item-text h4, .material-item-text h5, .material-item-text h6 {
    padding-top: 16px !important;
    padding-bottom: 8px !important;
  }
}

@media only screen and (max-width: 768px) {
  .material-item-youtube, .material-item-vimeo {
    min-height: auto;
  }
  .material-item-vimeo > div, .material-item-youtube > div {
    height: calc(100vw / 1.9) !important;
  }
}

@media only screen and (max-width: 576px) {

  .material-item-text ol, .material-item-text ul, .material-item-text div, .material-item-text span, .material-item-text p {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}
