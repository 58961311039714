.extra-materials {
  padding: 24px;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  /*margin-bottom: 32px;*/
}

.extra-materials-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.extra-item-content {
  cursor: pointer;
  margin-right: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
html[dir='rtl'] .extra-item-content {
  margin-right: 0;
  margin-left: 24px;
}

.extra-item-icon {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
}

.extra-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  transition: all .3s ease;
  flex: 1;
  padding-left: 16px;
  color: var(--text-color);
}
html[dir='rtl'] .extra-item-name {
  padding-left: 0;
  padding-right: 16px;
}
.extra-item-content:hover .extra-item-name {
  text-decoration-color: var(--text-color);
  text-decoration-style: normal;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
}

.extra-item-content:hover .extra-item-icon {
  border: 1px solid var(--text-color);
}

.extra-materials-item .btn {
  width: 140px;
  min-width: 140px;
}
@media only screen and (max-width: 992px) {
 .extra-materials {
   padding: 16px;
 }
  .extra-item-icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
  .extra-item-icon svg {
    width: 16px !important;
  }
  .extra-materials-item .btn {
    width: 120px;
    min-width: 120px;
  }
}
