
.course-article__small .sider-materials-list {
  overflow: initial;
}
.course-article__small-content-header {
  overflow: hidden;
  position: relative;
  padding: 16px 24px 0;
}
.course-article__small-content-header .title {
  padding-top: 32px;
}
.course-article__small-content-header .btn-outline {
  max-width: 240px;
  margin-bottom: 32px;
  margin-top: 64px;
}
.course-article__small-content-header .big-star {
  position: absolute;
  right: 32px;
  top: 16px;
  z-index: -1;
}
.course-article__small-content-header .big-star svg {
  width: 160px;
  height: 160px;
}



@media only screen and (max-width: 576px) {
  .course-article__small-content-header .btn-outline {
    max-width: 100%;
  }
}
