.login {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #F6F9FA !important;
  display: flex;
  position: relative;
  z-index: 1;
  /*background-size: 100%;*/
}

.login:before {
  content: '';
  z-index: -1;
  position: absolute;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  transform: rotate(25deg);
  background: center url("../../assets/images/brain.png") repeat;
}

.login-main {
  z-index: 2;
  position: relative;
  background-color: #fff;
  height: 100vh;
  width: 35%;
  min-width: 530px;
  padding: 32px 64px;
  display: flex;
  flex-direction: column;
  /*text-align: center;*/
}

.bg-wrap {
  flex-grow: 1;
  justify-content: center;
  /*background-size: ;*/
}

.bg {
  margin: 0 auto;
  flex-grow: 1;
  height: 100%;
  width: 60%;
  max-width: 1300px;
  background: center url("../../assets/images/bg.png") no-repeat;
  background-size: contain;
}

.login-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-wrap {
  background-color: var(--border-color);
  width: 154px;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 72px auto 32px;
}

.logo-wrap img {
  width: auto;
  height: 55%;
}
.forgot-password {
  display: block;
  cursor: pointer;
  position: absolute;
  top: -24px;
  right: 0;
  text-decoration-color: var(--text-color);
  text-decoration-style: normal;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  font-size: 14px;
  font-family: var(--font-medium);
}


.login button {
  height: 48px;
  width: 100%;
  /*margin-top: 56px;*/
}

.form-input-placeholder {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  font-family: var(--font-medium);
  color: var(--muted-color);
  font-size: 16px;
  left: 60px;
  transition: all .3s ease;
}
html[dir='rtl'] .form-input-placeholder {
  left: auto;
  right: 60px;
}

.form-input-wrap .input:empty + .form-input-placeholder {
  opacity: 1;
}

.form-input-wrap .input:focus + .form-input-placeholder,
.form-input-wrap.filled .form-input-placeholder,
.form-input-wrap .input:-webkit-autofill + .form-input-placeholder {
  transform: translateY(-20px);
  font-size: 10px;
}

.login-content form {
  max-width: 100%;
  width: 100%;
  min-width: 100px;
  /*padding: 0 64px;*/
}

.auth-form {
  width: 100%;
  height: 400px;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
  flex: 1;
  display: flex;
  align-items: center;
}

.auth-title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 96px;
}

.auth-title h3 {
  font-size: 32px;
  font-family: var(--font-bold);
  line-height: 48px;
  margin-bottom: 24px;
  text-align: center;

}

.auth-title.error h3 {
  color: var(--brown-color);
}

.auth-description {
  font-size: 16px;
  text-align: center;
  color: var(--muted-color);
  margin-bottom: 24px;
  line-height: 24px;
}

.auth-title.success {
  height: auto;
  margin-top: 48px;
  max-width: 340px;
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
  .login-main {
    width: 20%;
    min-width: 400px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .login-content form {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .bg-wrap {
    display: none;
  }
  .login-main {
    width: 100%;
    min-width: auto;
  }
  .login-content form {
    min-width: 100%;
  }
  .logo-wrap {
    width: 80px;
    height: 80px;
    margin: 24px auto;
  }

  .auth-title {
    height: auto;
  }
  .auth-title h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .auth-description {
    font-size: 14px;
    line-height: 18px;
  }
  .login input {
    height: 56px;
  }
}
@media only screen and (max-width: 576px) {
  .login-main {
    padding: 32px;
  }
}
