.timer {
  cursor: default;
  padding-top: 24px;
  user-select: none;
}

.timer-board {
  display: flex;
}

.timer-title {
  padding-bottom: 12px;
}

.timer-item {
  width: 140px;
  min-width: 140px;
  height: 150px;
  border-radius: 16px;
  background-color: var(--secondary-color);
  padding: 18px 0;
  margin: 0 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timer-item:first-child {
  margin-left: 0;
}

.timer-item:last-child {
  margin-right: 0;
}

.timer-item-scoreboard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
}

.timer-item-scoreboard span {
  position: relative;
}

.timer-item-scoreboard:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
}

.timer-item-title {
  color: var(--muted-color);
}

.timer-item.disabled .timer-item-scoreboard, .timer-item.disabled .timer-item-title {
  color: var(--border-color);
}

@media only screen and (max-width: 1300px) {
  .timer-item {
    width: 120px;
    min-width: 120px;
    margin: 0 8px;
    height: 150px;
  }
  .timer-item-scoreboard {
    font-size: 70px;
    line-height: 70px;
  }
}


@media only screen and (max-width: 992px) {
  .timer-item {
    flex: 1;
    min-width: 50px;
    margin: 0 8px;
    height: auto;
    border-radius: 8px;
    padding-bottom: 8px;
  }
  .timer-item-scoreboard {
    font-size: 32px;
    line-height: 32px;
  }
  .timer-item-title {
    padding-top: 4px;
    line-height: 21px;
    font-size: 12px;
  }
  .timer-title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .timer {
    padding-top: 16px;
  }
  .timer-item {
    width: 120px;
    min-width: 120px;
    margin: 0 8px;
    height: 150px;
    padding-bottom: 16px;
  }
  .timer-item-scoreboard {
    font-size: 70px;
    line-height: 70px;
  }
  .timer-item-title {
    line-height: 21px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .timer-item {
    flex: 1;
    min-width: 50px;
    margin: 0 8px;
    height: auto;
    border-radius: 8px;
    padding-bottom: 8px;
  }
  .timer-item-scoreboard {
    font-size: 32px;
    line-height: 32px;
  }
  .timer-item-title {
    padding-top: 4px;
    line-height: 21px;
    font-size: 12px;
  }
}
