.terms-content a, .terms-link {
  cursor: pointer;
  text-decoration-color: var(--text-color);
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  color: var(--muted-color);
}

.terms-content a:hover, .terms-link:hover {
  color: var(--text-color);
}

.terms-content h3 {
  font-family: var(--font-bold);
  font-size: 24px;
}

.terms-content h4 {
  font-family: var(--font-bold) !important;
  font-size: 20px;
}

.terms-content h3, .terms-content h4, .terms-content p {
  margin: 16px 0;
}

.terms-content ul {
  list-style: disc;
  padding-left: 32px;
}
.terms-content li {
  margin: 10px 0;
}