.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}
.logo img {
  width: auto;
  height: 30px;
  object-fit: cover;
}
