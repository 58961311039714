.task-answers {
  border: 1px solid var(--border-color);
  padding: 24px 0 16px 24px;
  border-radius: 16px;
  overflow: hidden;
}

html[dir='rtl'] .task-answers {
  padding-left: 0;
  padding-right: 24px;
}

.task-content {
  position: relative;
  min-height: 350px;
  max-height: 50vh;
  flex: 1;
  overflow: auto;
  padding-right: 24px;
}

html[dir='rtl'] .task-content {
  padding-left: 24px;
  padding-right: 0;
}

.task-status {
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-status.medium {
  justify-content: flex-start;
}

.task-status-content {
  background-color: var(--main-bg-color);
  display: flex;
  z-index: 1;
  position: relative;
  padding: 0 8px;
  align-items: center;
}

.task-status.medium .task-status-content, .task-status.small .task-status-content {
  padding: 0;
  background-color: transparent;
}

.task-status::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--text-color);
  left: 0;
  right: 0;
}

.task-status.small::before, .task-status.medium::before {
  display: none;
}

.task-status.rework:before {
  background-color: var(--brown-color);
}

.task-status.pending:before {
  background-color: var(--muted-color);
}

.task-status.accept path {
  stroke: var(--text-color);
}

.task-status.small {
  min-width: 14px;
  width: 14px;
}

.task-status-name {
  font-family: var(--font-refular);
  padding-left: 8px;
}

html[dir='rtl'] .task-status-name {
  padding-left: 0;
  padding-right: 8px;
}

.task-status.rework {
  color: var(--brown-color);
}



.task-status.pending {
  color: var(--muted-color);
}

.answer-item {
  padding: 24px 0;
  border-top: 1px solid var(--border-color);
}
.answer-item-user {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 8px;
  flex-grow: 1;
}

.answer-item .file {
  margin-top: 16px;
}

.answer-item .file-wrap {
  width: 100%;
}

.answer-item p {
  word-break: break-word;
}

.answer-item:first-child {
  border-top: none;
}

.task-answer-file {
  min-width: 48px;
  padding: 0 !important;
  height: 48px !important;
  width: 48px !important;
  margin-left: 16px !important;
}

html[dir='rtl'] .task-answer-file {
  margin-left: 0 !important;
  margin-right: 16px !important;
}

.task-answer-footer {
  position: relative;
  z-index: 1;
  padding-top: 8px;
  padding-right: 24px;
  background-color: #fff;
  max-width: 100vw;
  overflow: hidden;
}

html[dir='rtl'] .task-answer-footer {
  padding-right: 0;
  padding-left: 24px;
}

.task-answer-footer .btn-primary path {
  fill: #fff;
}

.task-answer-footer .muted-btn {
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 50%;
  margin-left: 16px;
}

.remove-task-file {
  transition: all .3s ease;
  position: absolute !important;
  right: 8px;
  z-index: 2;
}
html[dir='rtl'] .remove-task-file {
  right: auto;
  left: 8px;
}
.task-list-files {
  border-bottom: 0;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  padding-bottom: 6px;
  background-color: var(--secondary-color);
  position: relative;
  overflow-y: auto;
  max-height: 120px;
  margin-right: 64px;
}
html[dir='rtl'] .task-list-files {
  margin-right: 0;
  margin-left: 64px;
}

.task-list-files .file {
  border-radius: 0;
}

.task-list-files .file-wrap {
  margin-right: 0 !important;
}

.task-list-files + .task-list-files-separator + div .input {
  position: relative;
  border-top: none;
  border-radius: 0 0 16px 16px;
}

.task-answer-footer-input-line {
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
}

.task-answer-footer .input {
  resize: none;
  border-radius: 28px;
  border: none;
  z-index: 1;
  position: absolute;
}

.task-answer-footer-message-area {
  min-height: 50px;
  display: flex;
  align-items: flex-end;
  flex: 1;
  position: relative;
}

.task-list-files-separator {
  width: calc(100% - 64px);
  position: relative;
  background-color: var(--secondary-color);
  height: 1px;
}

.task-list-files-separator:after {
  content: '';
  position: absolute;
  left: 16px;
  top: 0;
  width: calc(100% - 32px);
  height: 1px;
  background-color: var(--border-color);
}

.task-answer-empty {
  width: 100%;
  position: absolute;
  bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 80px;
  padding-top: 40px;
  align-self: center;
}

.task-answer-empty-arrow {
  position: absolute;
  bottom: -65px;
  left: -80px;
}

@media only screen and (max-width: 768px) {
  .task-answers {
    border: none;
    height: 100%;
    padding-left: 16px;
    padding-top: 88px;
  }

  .task-content {
    padding-right: 16px;
    max-height: 100%;
    min-height: calc(100vh - 200px);
    height: calc(100vh - 200px);
    padding-bottom: 30px;
  }

  .task-answer-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
  }
}

