.group-chat-modal-container {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  max-width: 500px !important;
  overflow: visible !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.group-chat-modal {
  overflow: visible;
  background: linear-gradient(89.67deg, #F0ECFF 39.32%, #EEF6FE 97.12%);
  border-radius: 24px;
  position: relative;
  padding: 150px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.group-chat-modal .close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}

.group-chat-modal .group-chat-modal-head {
  text-align: center;
  padding: 0 46px 40px;
  overflow: visible;
}

.group-chat-modal .title-24 {
  margin-bottom: 16px !important;
}

.group-chat-modal .group-chat {
  top: -55px;
  right: auto;
  overflow: visible;
}

.group-chat-modal-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  top: -76px;
}

.group-chat-modal-icon img {
  width: 95px;
  height: 95px;
  z-index: 1;
}

.group-chat-modal-star {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 226px;
  height: 226px;
}

.group-chat-modal-star img {
  animation: 25s linear 0s infinite k-star-rotate;
  width: 90%;
  height: 90%;
}

@media only screen and (max-width: 768px) {

  .group-chat-modal-root .react-responsive-modal-container {
    display: flex !important;
    align-items: flex-end !important;
    justify-content: center !important;
  }

  .group-chat-modal-container {
    margin-bottom: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .group-chat-modal {
    border-radius: 24px 24px 0 0;
    padding-top: 120px;
  }

  .group-chat-modal .group-chat-star {
    right: -53px;
    top: -45px;
    width: 200px;
    height: 200px;
  }
}
