.form-input-wrap {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  align-items: center;
}

.form-input-wrap input {
  height: 68px;
  border-radius: 16px;
  padding-left: 60px;
  font-family: var(--font-bold);
  transition: all .3s ease;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color)
}
html[dir='rtl'] .form-input-wrap input {
  padding-left: 24px;
  padding-right: 60px;
}
.form-input-wrap input:focus, .form-input-wrap:hover input {
  border: 1px solid var(--text-color)
}
.input-icon {
  position: absolute;
  left: 24px;
}
html[dir='rtl'] .input-icon {
  left: auto;
  right: 24px;
}
.input-icon path {
  stroke: var(--text-color);
}

.form-input-wrap .input:empty + .form-input-placeholder {
  opacity: 1;
}

.form-input-wrap .input:focus + .form-input-placeholder,
.form-input-wrap.filled .form-input-placeholder,
.form-input-wrap .input:-webkit-autofill + .form-input-placeholder {
  transform: translateY(-20px);
  font-size: 10px;
}
