.progress {
  width: 100%;
  height: 2px !important;
  background-color: var(--border-color) !important;
  position: relative;
}
.available-progress {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: var(--muted-color);
}

.active-progress {
  left: 0;
  position: absolute;
  height: 100%;
  background-color: var(--text-color);
}
html[dir='rtl'] .available-progress, html[dir='rtl'] .active-progress {
  left: auto;
  right: 0;
}
