.loading-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loading-wrapper.loading .loading-wrapper-content {
  opacity: 0.4;
  pointer-events: none;
}
.loading-wrapper .spinner {
  top: auto;
}
