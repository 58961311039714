.video-player {
  width: 100%;
  position: relative;
}
.video-react-video {
  height: 100%;
  width: 100%;
  padding-top: 0;
}
.video-react {
  padding-top: 0 !important;
}
.video-player button, .video-react-control-bar {
  display: none;
}
