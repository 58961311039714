.course-sider {
  z-index: 1;
  position: fixed;
  top: 90px;
  left: 0;
  width: 465px;
  height: calc(100% - 90px);
  overflow: hidden;
  padding: 24px 0 0 24px;
  display: flex;
  flex-direction: column;
}

html[dir='rtl'] .course-sider {
  left: auto;
  right: 0;
  padding-left: 0;
  padding-right: 24px;
}

.sider-item {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 24px;
  padding: 24px;
  padding-left: 32px;
  cursor: default;
  color: var(--muted-color);
}

html[dir='rtl'] .sider-item {
  padding-left: 24px;
  padding-right: 32px;
}

.sider-item.active {
  background-color: var(--secondary-color);
}

.sider-item.disabled {
  user-select: none;
  pointer-events: none;
  color: var(--disabled-color);
}

.sider-item.disabled h5 {
  color: var(--disabled-color);
}

.sider-item .task-status-content path {
  stroke: var(--muted-color) !important;
}

.sider-header {
  padding: 24px 40px 24px 16px;
  cursor: pointer;
  transition: all .3s ease;
}

html[dir='rtl'] .sider-header {
  padding-left: 40px;
  padding-right: 16px;
  padding-bottom: 24px;
}

.sider-header.active {
  background: var(--secondary-color) !important;
}

.sider-header-content {
  transition: all .3s ease;
  position: relative;
  padding-left: 24px;
}

html[dir='rtl'] .sider-header-content {
  padding-left: 0;
  padding-right: 24px;
}

html[dir='rtl'] .sider-header-content h4 {
  text-align: right;
}

.sider-header-footer {
  color: var(--text-color);
  display: flex;
  padding-top: 16px;
  align-items: center;
  /*transition: all .3s ease;*/
}

.sider-header-footer .chat-link {
  margin-right: 24px;
}

html[dir='rtl'] .sider-header-footer .chat-link {
  margin-right: 0;
  margin-left: 24px;
}

.sider-header-content:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  background-color: #fff;
  height: 100%;
  transition: all .3s ease;
  opacity: 0;
}

html[dir='rtl'] .sider-header-content:after {
  left: auto;
  right: 0;
}

.sider-header:hover .sider-header-content:after {
  opacity: 1;
}

.sider-header.active .sider-header-content:after {
  opacity: 1;
  background-color: var(--text-color);
}

.sider-header:hover .sider-header-content, .sider-header.active .sider-header-content {
  margin-left: 16px;
}

html[dir='rtl'] .sider-header:hover .sider-header-content, html[dir='rtl'] .sider-header.active .sider-header-content {
  margin-left: 0;
  margin-right: 16px;
}

.sider-header .progress {
  background-color: #fff !important;
}

.sider-item-arrow {
  display: none;
  position: absolute;
  right: -24px;
  height: 100%;
}

html[dir='rtl'] .sider-item-arrow {
  right: auto;
  left: -24px;
  transform: rotate(180deg);
}

.sider-item-arrow svg {
  height: 100%;
  width: 40px;
}

.sider-item.active .sider-item-arrow {
  display: block;
}

.sider-lesson {
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 24px;
}

.sider-name {
  padding-top: 2px;
  transition: all .3s ease;
}

html[dir='rtl'] .sider-name {
  text-align: right;
}

.sider-item:hover .sider-name, .sider-item.active .sider-name, .sider-item:hover .sider-numeric, .sider-item.active .sider-numeric {
  color: var(--text-color);
}

.sider-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.sider-numeric {
  padding-right: 8px;
  margin-right: 16px;
  border-right: 1px solid var(--border-color);
  font-size: 24px;
  line-height: 18px;
  width: 52px;
  min-width: 52px;
  text-align: center;
  transition: all .3s ease;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

html[dir='rtl'] .sider-numeric {
  padding-left: 8px;
  padding-right: 0;
  margin-right: 0;
  margin-left: 16px;
  border-left: 1px solid var(--border-color);
  border-right: none;
}

.sider-materials-list {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 24px;
  padding-right: 8px;
  padding-left: 24px;
  padding-bottom: 24px;
  transform: scaleX(-1);
}

html[dir='rtl'] .sider-materials-list {
  padding-right: 24px;
  padding-left: 8px;
}

.sider-materials-list-content {
  transform: scaleX(-1);
}

.sider-date {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid var(--text-color);
  border-radius: 0;
  padding-bottom: 8px;
  color: var(--text-color);
  margin-right: 16px;
}
html[dir='rtl'] .sider-date {
  margin-right: 0;
  margin-left: 16px;
}
.sider-date span {
  white-space: nowrap;
}

.course-sider.small-container .sider-header {
  height: 82px;
}

.course-sider.small-container .sider-header .title {
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-sider.small-container .sider-header-footer {
  opacity: 0;
  /*position: absolute;*/
  height: 0;
  bottom: 0;
  padding-top: 0;
}

.course-sider:not(.small-container) .sider-header-footer {
  transition: all .3s ease;
}

.course-sider.small-container .sider-materials-list {
  padding-bottom: 50px;
}

@media only screen and (max-width: 1200px) {
  .course-sider {
    padding-top: 56px;
    width: 365px;
  }

  .sider-date, .sider-lesson, .sider-item {
    font-size: 14px;
    padding-left: 8px;
  }

  .sider-header {
    padding-right: 24px;
  }

  .sider-materials-list {
    padding-left: 8px;
  }

  .sider-numeric {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .course-sider {
    width: 100vw;
    background-color: #fff;
    padding-right: 24px;
    padding-top: 24px;
  }


  .course-content-wrap .breadcrumbs {
    display: none;
  }

  .sider-materials-list {
    margin-top: 0;
    padding-left: 16px;
    padding-right: 16px;
  }

  html[dir='rtl'] .sider-materials-list {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sider-item {
    padding: 32px 8px 0 !important;
  }

  .sider-date {
    position: sticky;
    top: 64px;
    background-color: #fff;
    z-index: 1;
    padding-bottom: 8px;
    margin-top: 8px;
    padding-top: 24px;
    margin-right: 0;
  }

  .sider-numeric {
    color: var(--text-color);
    text-align: left;
    min-width: 40px;
    width: 40px;
  }

  .sider-item.active {
    background-color: transparent;
  }

  .sider-item.active .sider-item-arrow {
    display: none;
  }
}
