.chat-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat-link .btn {
  padding: 0;
  min-width: 40px;
  width: 40px;
  height: 40px;
}

.chat-link-text {
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: relative;
}

.chat-link-text span {
  transition: all .3s ease;
}

.chat-link-arrow {
  transition: all .3s ease;
  opacity: 0;
  transform: translateX(-8px);
  margin: 0 16px;
}
html[dir='rtl'] .chat-link-arrow {
  transform: rotate(180deg) translateX(8px);;
}

.chat-link:hover .chat-link-arrow {
  opacity: 1;
  transform: translateX(0px);
}
html[dir='rtl'] .chat-link:hover .chat-link-arrow  {
  transform: rotate(180deg) translateX(0px);
}


.chat-link:hover .btn {
  box-shadow: 0 0 0 1px var(--text-color);
}

.chat-link.small .btn {
  min-width: 32px;
  width: 32px;
  height: 32px;
  min-height: 32px;
}
@media only screen and (max-width: 768px) {
  .chat-link-arrow {
    opacity: 1;
  }
  .chat-link-text {
    color: var(--text-color);
  }
  .chat-link .btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    min-height: 32px;
  }
  .chat-link svg {
    width: 20px !important;
  }
  .chat-link .btn svg {
    width: 12px !important;
  }
}
