::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

::selection {
  background: var(--text-color);
  color: #fff;
}

html {
  overscroll-behavior-y: none;
}

body {
  overscroll-behavior-y: none;
  position: relative;
  max-width: 100vw;
  font-family: var(--font-refular);
  font-size: 16px;
  line-height: 24px;
  padding-top: 0;
  margin: 0;
  background-color: var(--main-bg-color);
  overflow-x: hidden;
  min-height: 100vh;
}


h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-refular);
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

p {
  margin-bottom: 0;
}

a {
  color: var(--muted-color);
  text-decoration: none;
}

a:hover, a:active {
  color: var(--text-color);
}


main {
  padding: 90px 0 0;
}
.text-right {
  text-align: right;
}
.btn {
  cursor: pointer;
  position: relative;
  outline: none;
  width: 100%;
  min-height: 40px;
  height: 40px;
  border-radius: 50px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 32px;
  transition: all .3s ease;
  color: var(--text-color);
}

.btn.loading {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  font-size: 16px;
  background-color: var(--text-color);
}

.btn-primary:not(.disabled):hover {
  box-shadow: 0 0 0 1px var(--text-color);
  background-color: var(--text-color);
  color: #fff;
}

.btn-primary.disabled {
  pointer-events: none;
  background-color: var(--border-color);
}

.muted {
  color: var(--muted-color);
}

.brown {
  color: var(--brown-color);
}

.muted-14 {
  font-size: 14px;
  line-height: 21px;
  color: var(--muted-color);
}

.muted-icon path, .muted-icon rect {
  fill: var(--border-color);
}

.muted-12 {
  font-size: 12px;
  line-height: 14px;
  color: var(--muted-color);
}

.bold {
  line-height: 24px;
  font-family: var(--font-bold) !important;
}

.bold-14 {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--font-bold);
}

.bold-20 {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--font-bold);
}

.medium {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-medium);
}

.text-20 {
  font-size: 20px;
  line-height: 30px;
}

.title-24 {
  font-size: 24px;
  line-height: 36px;
  font-family: var(--font-bold);
}

.title-32 {
  font-size: 32px;
  line-height: 48px;
  font-family: var(--font-bold);
}

.title {
  font-size: 24px;
  line-height: 36px;
  font-family: var(--font-bold);
}

.medium-12 {
  font-size: 12px;
  line-height: 12px;
  font-family: var(--font-medium);
}

.text-14 {
  font-size: 14px;
  line-height: 21px;
}

.text-12 {
  font-size: 12px;
  line-height: 14px;
}

.medium-14 {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--font-medium);
}

.medium-20 {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font-medium);
}

.medium-24 {
  font-size: 24px;
  line-height: 29px;
  font-family: var(--font-medium);
}

.gradient-bg {
  background: var(--main-gradient-bg);
}

select {
  font-family: var(--font-bold);
  font-size: 16px;
  width: 100%;
  height: 68px;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-color);
  border-radius: 16px;
  outline: none;
  padding-left: 24px;
  padding-right: 56px;
  color: var(--text-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
html[dir='rtl'] select {
  padding-left: 56px;
  padding-right: 24px;
}
select option {
  color: var(--text-color);
  font-family: var(--font-refular);
  background: #fff;
}

select option:hover, select option:checked {
  background: var(--secondary-color) !important;
  /*box-shadow: 0 0 10px 100px red inset;*/
}

.select-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.select-wrap-icon {
  position: absolute;
  pointer-events: none;
  right: 24px;
  width: 24px;
  background-color: var(--secondary-color);
}

html[dir='rtl'] .select-wrap-icon {
  right: auto;
  left: 24px;
}

.input {
  font-size: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-color);
  background-color: var(--secondary-color);
  border-radius: 28px;
  outline: none;
  padding: 0 24px;
  font-family: var(--font-refular);
}

.input::placeholder {
  color: var(--muted-color);
  font-family: var(--font-refular);
  font-size: 16px;
}

textarea.input {
  border-radius: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.input-small {
  height: 32px;
  font-size: 14px;
  font-family: var(--font-medium);
}

.input-small::placeholder {
  font-size: 14px;
  font-family: var(--font-refular);
}

.page-404 {
  padding-top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.page-404 h4 {
  position: relative;
  z-index: 1;
  padding-top: 24px;
}

.page-404 a {
  max-width: 232px;
  margin-top: 48px;
}

.pointer {
  cursor: pointer;
}

.page-title-wrap {
  display: flex;
  align-items: center;
}

.page-title {
  font-size: 24px;
  font-family: var(--font-bold);
  line-height: 24px;
}

.title-38 {
  font-size: 38px;
  font-family: var(--font-bold);
  line-height: 48px;
}


.title-48 {
  font-size: 48px;
  font-family: var(--font-bold);
  line-height: 56px;
}

.separator {
  border-bottom: 1px solid var(--border-color);
}

.bg-gradient-0 {
  background: linear-gradient(86.01deg, #FFF885 -51.98%, #E2F6EC 65.11%);
}

.bg-gradient-1 {
  background: linear-gradient(89.67deg, #F0ECFF 39.32%, #EEF6FE 97.12%);
}

.bg-gradient-2 {
  background: linear-gradient(65.31deg, #FDE4F9 2.27%, #FCECD3 96.33%);
}

.bg-gradient-3 {
  background: linear-gradient(92.56deg, #FCEDE7 11.59%, #FEFBF1 97.48%);
}

.bg-gradient-4 {
  background: linear-gradient(113.55deg, #E4E5F9 1.83%, #E2F6EC 116.65%);
}


.btn-circle {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  min-width: 32px;
  width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  transition: all .3s ease;
  font-family: var(--font-medium);
  font-size: 14px;
  padding: 0;
}

.btn-circle:hover, .btn-circle.active {
  border-color: var(--text-color);
}

.btn-circle-small {
  min-width: 24px;
  width: 24px;
  height: 24px;
  min-height: 24px;
}

.btn-circle.remove {
  padding: 0 !important;
}


.btn-outline {
  font-size: 14px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.btn-outline:hover {
  border-color: var(--text-color);
}

.react-responsive-modal-modal {
  text-align: initial;
}

.btn-transparent {
  border: none;
  outline: none;
  font-size: 14px;
  font-family: var(--font-medium);
  background-color: transparent;
}

.gallery-modal {
  background-color: transparent;
  width: 100vw;
  margin: 0;
  text-align: center;
  padding: 0;
  box-shadow: none;
  height: 100vh;
  max-width: 100%;
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider {
  text-align: center;
  width: 100%;
}

.close-image-gallery {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  cursor: pointer;
}

.big-star.animated {
  animation: 25s linear 0s infinite k-star-rotate;
}

@keyframes k-star-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


.header-center {
  overflow: hidden;
  display: flex;
  text-align: center;
  height: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  animation: .3s linear 0s none k-header-center-shown;
}

@keyframes k-header-center-shown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Toastify__toast-container {
  left: auto;
  top: 0;
  right: 0;
  box-shadow: none !important;
  padding: 0;
  width: 400px;
  max-width: 100vw;
}

.Toastify__toast {
  min-height: 90px !important;
  border-radius: 0;
  box-shadow: none;
  background-color: var(--secondary-color);
  padding: 8px 24px;
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.Toastify__progress-bar {
  background: var(--text-color) !important;
  height: 1px;
}

.Toastify__toast-icon {

}

.animate__animated {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

html[dir='rtl'] .animate__fadeInRight {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

html[dir='rtl'] .animate__fadeInLeft {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.youtube-chat {
  height: 40vh;
}

.input-label {
  text-align: left;
  color: var(--muted-color);
  margin-bottom: 8px;
}

html[dir='rtl'] .input-label {
  text-align: right;
}

.progress-title {
  display: flex;
}

.progress-title .muted-12 {
  padding-left: 16px;
}

html[dir='rtl'] .progress-title .muted-12 {
  padding-left: 0;
  padding-right: 16px;
}

.rtl-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.logo-bg {
  position: absolute;
  animation: 25s linear 0s infinite k-star-rotate;
}
.logo-bg + img{
  position: relative;
  z-index: 1;
}
.logo-bg svg {
  transition: all .3s ease;
}

@keyframes k-star-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
