.courses {
  padding: 24px 40px 40px;
}

.courses-list {
  position: relative;
  padding-top: 32px;
}

.courses-item {
  position: relative;
  padding: 32px 190px 32px 40px;
  background-color: var(--secondary-color);
  border-radius: 16px;
  overflow: hidden;
  height: 100%;
}

html[dir='rtl'] .courses-item {
  padding: 32px 40px 32px 190px;
}

html[dir='rtl'] .courses-item .title-32 {
  text-align: right;

}

.courses-item .big-star {
  position: absolute;
  top: 50px;
  right: -111px;
}

html[dir='rtl'] .courses-item .big-star {
  left: -111px;
  right: auto;
}

.courses-item-content {
  display: flex;
  /*overflow: hidden;*/
  flex-direction: column;
  cursor: default;
  position: relative;
  z-index: 0;
}

.stream-name {
  padding-right: 64px;
  height: 21px;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 21px;
}

html[dir='rtl'] .stream-name {
  padding-right: 0;
  padding-left: 64px;
  text-align: right;
}

.course-desc {
  font-size: 14px;
  color: var(--muted-color);
  height: 40px;
  line-height: 21px;
}

html[dir='rtl'] .course-desc {
  text-align: right;
}

.courses-item-content .btn {
  max-width: 180px;
}

.courses-item-content .btn:hover {
  max-width: 200px;
  box-shadow: none;
}

.courses-item-date {
  position: absolute;
  right: -150px;
  top: -4px;
}

html[dir='rtl'] .courses-item-date {
  left: -150px;
  right: auto;
}

.mark-passed {
  opacity: 1;
  pointer-events: none;
  white-space: nowrap;
  transition: all .3s ease;
  cursor: pointer;
  display: inline-flex;
  color: var(--muted-color);
  position: absolute;
  left: 224px;
}

html[dir='rtl'] .mark-passed {
  left: auto;
  right: 224px;
}

.mark-passed-icon {
  padding-left: 8px;
  opacity: 0;
  transition: all .3s ease;
}

html[dir='rtl'] .mark-passed-icon {
  padding-left: 0;
  padding-right: 8px;
}

.mark-passed:hover .mark-passed-icon {
  opacity: 1;
}

.mark-passed:hover {
  color: var(--text-color);
  opacity: 1;
}

.mark-passed path {
  stroke: var(--text-color);
}

.courses-item:hover .mark-passed {
  opacity: 1;
  pointer-events: auto;
}

.courses-item-btn-payment {
  min-width: 300px;
  margin-top: 16px;
}

.courses-item-btn-price {
  position: absolute;
  right: 16px;
}

html[dir='rtl'] .courses-item-btn-price {
  right: auto;
  left: 16px;
}


.payment-modal {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
}

.payment-modal .react-responsive-modal-modal {
  border-radius: 40px;
  background: #fff;
  box-shadow: none;
  width: 90%;
  max-width: 1100px;
  padding: 24px;
}

.wpwl-container iframe {
  min-height: auto !important;
  /*height: auto!important;*/
}

.wpwl-container {
  overflow-y: auto;
  height: 100%;
}

.hyperpay-modal-cost {
  font-size: 24px;
  font-family: var(--font-bold);
  line-height: 36px;
}

.logo-wrap-star {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 100px;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;
  transition: all .3s ease;
  width: 100px;
}

.logo-wrap-star img {
  height: 56px;
  transition: all .3s ease;
}


.payment-modal-info.visible .logo-wrap-star {
  width: 64px;
  height: 64px;
}

.payment-modal-info.visible .logo-wrap-star svg {
  width: 64px !important;
  height: 64px !important;
}

.payment-modal-info.visible .logo-wrap-star img {
  height: 36px;
}

.wpwl-form {
  background: var(--secondary-color) !important;
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 0;
  height: 100%;
}

.wpwl-button-pay {
  cursor: pointer;
  position: relative;
  outline: none;
  font-size: 16px;
  min-height: 40px;
  height: 40px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0 32px;
  transition: all .3s ease;
  color: #fff;
  border-radius: 28px;
  background-color: var(--text-color) !important;
}

.wpwl-form {
  color: var(--text-color);
  font-family: var(--font-refular);
}

.wpwl-label-brand {
  display: none;
}

.wpwl-hint-cardNumberError,
.wpwl-hint-expiryError,
.wpwl-hint-cardHolderError,
.wpwl-hint-cvvError {
  color: var(--error-color) !important;
  font-family: var(--font-medium);
  position: absolute;
  bottom: -20px;
}

.wpwl-form-card {
  background: var(--main-bg-color);
  box-shadow: none;
  border: 0;
}

.wpwl-label {
  color: var(--muted-color);
  font-size: 14px !important;
}

.wpwl-control, input.wpwl-control, iframe.wpwl-control {
  height: 32px;
  padding: 0 16px;
  border-radius: 8px;
  color: var(--text-color) !important;
  border-color: var(--border-color);
  background-color: #fff;
}

.input-text:focus, input[type=text]:focus, input[type=tel]:focus, input[type=url]:focus, input[type=password]:focus, input[type=search]:focus, textarea:focus {
  background-color: white !important;
  border-color: var(--border-color);
}

.wpwl-brand-SOFORTUEBERWEISUNG, .wpwl-brand-GIROPAY {
  cursor: pointer;
  background-color: white !important;
}

.wpwl-brand, .wpwl-img {
  margin: 0 0 0 auto;
}

.payment-modal-card {
  position: relative;
  flex: 1;
  margin-left: 12px;
  height: 276px;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid var(--secondary-color);
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
}

.payment-modal-card label {
  font-size: 14px;
  padding-bottom: 2px;
}

.payment-modal-card .form-control {
  height: 32px;
  font-size: 14px;
  border-radius: 8px;
  border-color: var(--border-color);
  padding: 0 16px;
}

.payment-modal-card .form-control:focus {
  box-shadow: none;
  border-color: #86b7fe;
}

.payment-modal-card .form-control::-webkit-calendar-picker-indicator {
  display: none !important;
}

.payment-modal-card input::placeholder {
  color: var(--muted-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

.payment-modal-info {
  position: relative;
  margin-right: 12px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  flex: 1;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /*padding: 24px;*/
}

.payment-modal-info .logo-wrap {
  margin-top: -56px;
}

.payment-modal-info-stream-about {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 16px;
  padding-top: 32px;
}

.payment-modal-info-stream-about .logo-wrap {
  height: 56px !important;
  width: 56px !important;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  top: -40px;
  left: 24px;
}

.payment-modal-info-stream-about .logo-wrap svg {
  width: 56px;
  height: 56px;
}

.payment-modal-info-item.own .payment-modal-info-stream-about .logo-wrap svg {
  width: 66px;
  height: 66px;
}

.payment-modal-info-stream-about .logo-wrap img {
  width: 40px;
  height: auto;
  max-height: 40px;
  object-fit: contain;
}

.payment-modal-info .payment-modal-desc {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 96px;
  word-break: break-word;
  padding: 0 8px 16px;
}

.payment-modal-info-item.own .payment-modal-desc {
  margin-bottom: 0;
  padding-bottom: 0;
}

.wpwl-group-submit {
  margin-top: 4px !important;
}

.payment-modal-content {
  display: flex;
  align-items: flex-start;
}

.payment-modal-info-item {
  transition: all .3s ease;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}


.payment-modal-info-item.own {
  border: 1px solid #000;
  background-color: #fff;
  padding-bottom: 0;
}

.payment-modal-info-item:hover {
  background-color: var(--secondary-color);
}

.payment-modal-info-item-logo {
  margin-right: 8px;
  margin-top: 0 !important;
  height: 56px;
  min-width: 56px;
  width: 56px;
  margin-bottom: 0;
}

.payment-modal-info-item.own .payment-modal-info-item-logo {
  height: 66px;
  width: 66px;
  min-width: 66px;
}

.payment-modal-info-item-logo svg {
  width: 56px;
  min-width: 56px;
}

.payment-modal-info-item.own .payment-modal-info-item-logo svg {
  width: 66px;
}

.payment-modal-info-item-logo img {
  width: 35px;
  height: auto;
  object-fit: contain;
}

.payment-modal-info-item.own .payment-modal-info-item-logo img {
  width: 40px;
}

.payment-modal-info-item.own .bold {
  font-size: 16px;
}

.payment-modal-info-item-link {
  cursor: pointer;
  transition: all .3s ease;
  color: var(--text-color);
  display: block;
}

.payment-modal-info-item-link:hover {
  text-decoration: underline;
}

.payment-modal-info-item .date-info {
  font-size: 14px;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4px;
  line-height: 18px;
}

.payment-modal-info-item .date-info-title {
  width: auto;
  flex: 0;
  white-space: nowrap;
  font-size: 14px;
  padding-right: 4px;
}
.payment-modal-info-item .date-info-icon {
  margin-right: 4px;
}
.payment-modal-info-item .date-info-icon svg {
  width: 16px !important;
  height: 16px !important;
}

.payment-modal-info-item .date-info .muted {
  padding-left: 4px;
  padding-top: 0;
  align-self: center;
}

.payment-modal-info-list {
  overflow: auto;
  flex: 1;
  max-height: 324px;
}

.payment-modal-info-list .payment-modal-info-item:last-child {
  border: 0;
}

.payment-modal-info-all-rates {
  text-align: center;
  padding-bottom: 8px;
  margin-top: -24px;
  padding-top: 32px;
  transition: all .3s ease;
  cursor: pointer;
}

.payment-modal-info-all-rates:hover {
  background-color: var(--secondary-color);
}

.payment-modal-info-item .date-info-title {
  width: auto;
  flex: 0 1;
  white-space: nowrap;
  font-size: 14px;
  padding-right: 4px;
  padding-bottom: 0;
}

.payment-modal-info-item-arrow {
  transition: all .3s ease;
  transform: rotate(90deg);
  width: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
}

.payment-modal-info-item-arrow path {
  transition: all .3s ease;
  fill: var(--muted-color);
}

.payment-modal-info:hover .payment-modal-info-item-arrow path {
  fill: var(--text-color);
}

.payment-modal-info-item.visible .payment-modal-info-item-arrow {
  transform: rotate(-90deg);
}

.payment-modal-info-item-price {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 42px;
}
.payment-modal-info-item-header {
  align-items: center;
  display: flex;
  padding: 8px;
}
@media only screen and (min-width: 1700px) {
  .course-item-col {
    width: 33% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .mark-passed {
    font-size: 14px;
    opacity: 1;
    pointer-events: auto;
    left: 216px;
  }
}

@media only screen and (max-width: 992px) {
  .payment-modal .title {
    font-size: 16px;
    line-height: 20px;
  }

  .hyperpay-modal-cost {
    line-height: 20px;
    font-size: 20px;
  }

  .payment-modal-content {
    flex-direction: column;
    align-items: initial;
  }

  .payment-modal-info {
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }

  .payment-modal-card {

    margin-left: 0;

    width: auto;
  }

  .mark-passed {
    transition: none;
  }

  .payment-modal .react-responsive-modal-modal {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .payment-modal-desc {
    font-size: 14px;
    line-height: 21px;
  }
  .courses {
    padding: 0 16px 16px;
  }

  .courses .page-title-wrap {
    display: none;
  }

  .courses .tabs {
    padding-top: 0;
  }

  .courses .tab-item {
    flex: 1
  }

  .courses-item {
    padding: 24px !important;
  }

  .courses-item-date {
    position: relative;
    right: auto;
    padding-top: 32px;
  }

  html[dir='rtl'] .courses-item-date {
    left: auto;
  }

  .stream-name {
    margin-bottom: 32px;
  }

  .courses-item .title-32 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px !important;
  }

  .courses-item .big-star {
    width: 160px;
    height: 160px;
    right: 40px;
    top: auto;
    bottom: 40px;
  }

  html[dir='rtl'] .courses-item .big-star {
    left: 40px;
    right: auto;
  }

  .course-desc {
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .mark-passed {
    left: auto !important;
    right: auto !important;
    margin: 0 auto;
    position: relative;
    margin-top: 24px;
  }

  .btn-go-to, .courses-item-btn-payment {
    min-width: 100%;
    width: 100% !important;
    max-width: 100% !important;
  }

  .payment-modal-info-item-arrow {
    margin-left: 8px;
    top: 24px;
    right: 16px;
  }
  .payment-modal-info-item-header {
    padding-right: 32px;
    padding-bottom: 4px;
    flex-wrap: wrap;
  }
  .payment-modal-info-item-price {
    width: 100%;
    padding-right: 0;
    justify-content: flex-start;
    padding-left: 56px;
    padding-top: 4px;
  }

  .payment-modal-info-item .date-info {
    font-size: 12px;
    position: relative;
    width: 100%;
    right: 0;
    margin: 0;
    top: 0;
  }
  .payment-modal-info-item-logo {
    min-width: 48px !important;
    width: 48px !important;
    height: 48px !important;
  }
  .payment-modal-info-item-logo svg {
    width: 48px !important;
    min-width: 48px !important;
  }
  .payment-modal-info-item-logo img {
    width: 33px !important;
  }
}


.paypal-img {
  width: 100%;
  max-width: 224px;
}
.payment-modal-card.paypal {
  padding: 32px;
  height: auto;
  align-items: flex-start;
  max-height: 276px;
}
.payment-modal-card.paypal img  {
  height: 56px;
  width: auto;
}
