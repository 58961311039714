.date-info {
  display: inline-block;
  text-align: right;
}

html[dir='rtl'] .date-info {
  text-align: left;
}
.date-info-title {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 20px;
}
.date-info .muted {
  padding-top: 2px;
}
.date-info-icon {
  margin-right: 8px;
  width: 24px;
  min-width: 24px;
  text-align: center;
}
html[dir='rtl'] .date-info-icon {
  margin-right: 0;
  margin-left: 8px;
}
@media only screen and (max-width: 768px) {
  .date-info {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .date-info-title {
    font-size: 14px;
    padding-bottom: 0;
    padding-right: 16px;
  }
  html[dir='rtl'] .date-info-title {
    padding-right: 0;
    padding-left: 16px;
  }
  .date-info .muted {
    padding-top: 0;
    padding-left: 8px;
  }
  html[dir='rtl']  .date-info .muted {
    padding-left: 0;
    padding-right: 8px;
  }
}

@media only screen and (max-width: 576px) {
  .date-info-title {
    padding-right: 8px;
  }
  .date-info {
    font-size: 13px;
  }
  .date-info-title {
    font-size: 13px;
  }
  .date-info-icon {
    width: 20px;
    min-width: 20px;
  }
  .date-info-icon svg {
    width: 24px !important;
    height: 24px !important;
  }
}
