.course-article__small .course-content-right {
  padding-left: 0;
}
html[dir='rtl'] .course-article__small .course-content-right {
  padding-right: 0;
}
.course-article__small .course-content {
  padding: 16px 16px 0 16px !important;
}

.course-article__small .content-wrap {
  margin-top: -16px;
}

.course-article__small .course-content-padder {
  padding: 0 !important;
}

.course-article__small .course-content-numeric {
  position: relative;
  padding-top: 0;
  width: auto;
  font-size: 24px;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--border-color);
  display: inline-block;
  margin-bottom: 7px;
  min-width: 12px;
  text-align: center;
  line-height: 18px;
}



.course-article__small .material-type {
  font-size: 12px;
}

.course-article__small .course-content .title-32 {
  padding-left: 0;
  border-left: none;
}

.course-article__small .course-content .title-32:after {
  display: none;
}

.course-article__small .course-content .title-32 {
  font-size: 20px;
  line-height: 30px;
}

.course-article__small .next-material {
  max-width: 100%;
  margin-left: 0;
  margin-right: 16px;
}
html[dir='rtl'] .course-article__small .next-material {
  margin-right: 0;
  margin-left: 16px;
}

@media only screen and (min-width: 768px) {
  .course-article__small .course-content {
    padding: 16px 24px 0 24px !important;
  }

}

@media only screen and (min-width: 992px) {
  .course-article__small .course-content {
    padding: 16px 40px 0 40px !important;
  }
}
