.page-container {
  height: 100vh;
}

.page-right-col {
  height: 100%;
  padding-top: 88px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.page-left-col {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-left: 16px !important;
  height: 100%;
  /*border-left: 1px solid var(--border-color);*/
  padding-right: 0 !important;
}

.page-left-col-header {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 16px;
}

.page-left-content {
  padding: 16px 16px 0 0;
  overflow-y: hidden;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.page-right-hidden {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100%;
  width: 100%;
  background: var(--secondary-color);
  transition: all .3s ease;
  border-top-left-radius: 24px;
  padding-top: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page-right-hidden.show {
  right: 0;
}

.page-right-hidden-header {
  display: flex;
  padding: 0 32px 24px 32px;
  align-items: center;
}

.page-right-hidden-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: visible;
  overflow-y: auto;
  padding: 0 32px 24px;
}

.error-boundary {
  margin: 0 auto;
  max-width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hidden-content-empty {
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
