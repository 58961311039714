header {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: var(--main-bg-color);
  z-index: 5;
  border-bottom: 1px solid var(--border-color);
}

.header-right {
  display: flex;
  align-items: center;
  position: relative;
  transition: all .3s ease;
}

.header-profile {
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: all .3s ease;
}

.header-profile:hover, .header-right.active.profile .header-profile, .header-right.active.notifications .notification {
  border-color: var(--text-color);
}

.active-btn-border {
  content: '';
  position: absolute;
  bottom: -25px;
  width: 40px;
  height: 1px;
  right: -40px;
  opacity: 0;
  background-color: var(--text-color);
  transition: all .3s ease;
  z-index: 1;
}

html[dir='rtl'] .active-btn-border {
  right: auto;
  left: -40px;
}

.header-right.active .active-btn-border {
  opacity: 1;
}

.header-right.active.profile .active-btn-border {
  right: 0;
}

html[dir='rtl'] .header-right.active.profile .active-btn-border {
  right: auto;
  left: 0;
}

.header-right.active.notifications .active-btn-border {
  right: 64px;
}

html[dir='rtl'] .header-right.active.notifications .active-btn-border {
  right: auto;
  left: 64px;
}

.home-link {
  display: block;
  text-decoration: none;
}

.header-right-hidden {
  min-width: 280px;
  position: fixed;
  top: 90px;
  right: -800px;
  width: 400px;
  max-width: calc(100vw - 24px);
  height: calc(100vh - 90px);
  /*height: calc(var(--vh, 1vh) * 100 - 90px);*/
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--border-color);
  background-color: #fff;
  transition: right .5s ease;
}

html[dir='rtl'] .header-right-hidden {
  right: auto;
  left: -800px;
  border-right: 1px solid var(--border-color);
  border-left: none;
}

.header-right-hidden.show {
  right: 0;
}

html[dir='rtl'] .header-right-hidden.show {
  right: auto;
  left: 0;
}

.header-right-hidden-content {
  overflow-y: auto;
  height: 200%;
  padding: 16px;
  padding-bottom: 0;
  -webkit-overflow-scrolling: touch;
  margin-top: 8px;
  background-color: #fff;
}

.header-right-hidden-top {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right-hidden-top .btn-circle {
  min-width: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: -20px;
  background-color: #fff;
  top: 0
}

html[dir='rtl'] .header-right-hidden-top .btn-circle {
  left: auto;
  right: -20px;
  transform: rotate(180deg);
}

header .btn-circle {
  min-width: 40px;
  height: 40px;
}

header.with-title .header-profile, header.with-title .header-right.active .active-btn-border {
  margin-right: -56px;
}

html[dir='rtl'] header.with-title .header-profile, html[dir='rtl'] header.with-title .header-right.active .active-btn-border {
  margin-right: 0;
  margin-left: -56px;
}

@media only screen and (max-width: 2150px) {
  .header-right-hidden {
    width: calc(100vw - 1304px);
    right: calc(-100vw + 1124px);
  }
}

@media only screen and (max-width: 1745px) {
  .header-right-hidden {
    width: 400px;
    right: -800px;
  }
}

@media only screen and (max-width: 992px) {
  header {
    padding: 0 24px;
  }

  /*.header-right-hidden.show {*/
  /*  right: 0;*/
  /*}*/
}

@media only screen and (max-width: 768px) {
  header {
    height: 72px;
    padding: 0 16px 16px;
    align-items: flex-end;
  }

  .header-right-hidden {
    top: 72px;
    height: calc(100vh - 72px);
  }

  .active-btn-border {
    bottom: -17px;
  }

  .notification {
    margin: 0 16px 0 0 !important;
  }

  html[dir='rtl'] .notification {
    margin: 0 0 0 16px !important;
  }

  .header-right.active.notifications .active-btn-border {
    right: 56px;
  }

  html[dir='rtl'] .header-right.active.notifications .active-btn-border {
    right: auto;
    left: 56px;
  }
}

@media only screen and (min-width: 2151px) {
  .header-right-hidden {
    width: 600px;
    max-width: calc(100vw - 1767px);
    min-width: auto;
  }
}

@media only screen and (min-width: 768px) {
  header.with-title .header-profile {
    margin-right: -80px;
  }

  html[dir='rtl'] header.with-title .header-profile {
    margin-right: 0;
    margin-left: -80px;
  }

  header.with-title .notification {
    margin-right: 40px;
  }

  html[dir='rtl'] header.with-title .notification {
    margin-right: 0;
    margin-left: 40px;
  }

  header.with-title .header-right.active .active-btn-border {
    margin-right: -66px;
  }

  html[dir='rtl'] header.with-title .header-right.active .active-btn-border {
    margin-right: 0;
    margin-left: -66px;
  }
}
