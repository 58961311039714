.file {
  appearance: auto;
  display: flex;
  padding: 12px 16px;
}

.file-wrap {
  background-color: var(--secondary-color);
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  width: calc(50% - 4px);
  min-width: 250px;
}

.file-icon {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--text-color);
  margin-right: 8px;
}

html[dir='rtl'] .file-icon {
  margin-right: 0;
  margin-left: 8px;
}
.file-icon path {
  stroke: #fff;
}
.file-info {
  overflow: hidden;
  display: grid;
  align-items: center;
  /*justify-content: space-between;*/
  height: 32px;
  flex: 1;
}

.file-title {
  color: var(--text-color);
  /*word-break: break-word;*/
}
html[dir='rtl'] .file-title  {
  text-align: right;
}
