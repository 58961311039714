.avatar {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.avatar.small {
  min-width: 32px;
  width: 32px;
  height: 32px;
}
.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.no-avatar {
  text-transform: uppercase;
  color: var(--muted-color);
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-color);
  align-items: center;
  justify-content: center;
  font-family: var(--font-refular);
}
.avatar-edit {
  cursor: pointer;
  position: absolute;
  bottom: -45px;
  width: 100%;
  left: 0;
  height: 40px;
  background-color: rgba(17, 25, 48, 0.64);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom .3s ease;
  z-index: 1;
}

.avatar:hover .avatar-edit {
  bottom: 0;
}
@media only screen and (max-width: 1200px) {
  .avatar-edit {
    bottom: 0;
  }
}
