@media only screen and (max-width: 2150px) {
}

@media only screen and (max-width: 1745px) {
}

@media only screen and (max-width: 1200px) {
  .title-48 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 992px) {
  .gallery-modal {
    /*width: 100%;*/
  }

  .title-48 {
    font-size: 36px;
    line-height: 44px;
  }

  .btn {
    padding: 0 16px;
    font-size: 14px;
  }

  .see-material-btn {
    max-width: 240px;
  }

  .body-overflow {
    /*position: fixed;*/
    /*overflow: hidden;*/
    /*max-height: 100vh !important;*/
  }
}

@media only screen and (max-width: 768px) {
  .course-article__small {
    padding-top: 72px;
  }

  .btn-outline {
    border-color: var(--text-color);
  }

  main {
    padding-top: 72px;
  }

  .Toastify__toast {
    min-height: 72px !important;
  }
}

@media only screen and (max-width: 576px) {
  .see-material-btn {
    max-width: 100%;
  }
}
