.my-toast-container {
  display: flex;
  align-items: center;
}
.my-toast-icon {
  margin-bottom: 0 !important;
}
.my-toast-success .my-toast-icon path {
  stroke: var(--text-color);
}
