.input-file {
  position: relative;
  cursor: pointer;
}
.input-file label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-file input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}
.input-file__progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 16px;
  overflow: hidden;
}
.input-file__progress__bar {
  background: var(--main-gradient-bg);
  width: 100%;
  height: 100%;
}
html[dir='rtl'] .input-file svg{
}
