.notification {
  position: relative;
  cursor: pointer;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color);
  margin: 0 24px;
  transition: all .3s ease;
}

.notification:hover, .notification.active {
  border-color: var(--text-color);
}

.notification-hidden {
  /*display: none;*/
  z-index: 5;
  position: absolute;
  width: 100%;
  height: calc(100vh - 88px);
  top: 88px;
  background-color: var(--secondary-color);
  border-top-left-radius: 24px;
}

.notifications-list {
  padding-bottom: 16px;
  flex: 1;
  position: relative;
}

.notification-badge-wrap {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 4px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
html[dir='rtl'] .notification-badge-wrap {
  right: auto;
  left: 4px;
}
.notification-badge {
  position: absolute;
  background-color: var(--text-color);
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border-radius: 9px;
  border: 2px solid var(--main-bg-color);
  font-family: var(--font-medium);
  font-size: 10px;
  color: #fff;
}

.notifications-list-item {
  border-radius: 16px;
  position: relative;
  display: flex;
  padding: 16px;
  border-top: 1px solid var(--border-color);
  background-color: var(--secondary-color);
}

.notifications-list-item.read {
  background-color: transparent;
  border-radius: 0;
}

.notifications-list-item:first-child {
  border-top: none;
}

.notifications-list-item:not(.read):before {
  content: '';
  position: absolute;
  left: -16px;
  width: calc(100% + 24px);
  height: 100%;
  top: 0;
  /*background-color: var(--welcome-bg-color);*/
  border-radius: 16px;
}

.notifications-list-item:not(.read) + .notifications-list-item:not(.read) {
  margin-top: 8px;
}

.notifications-list-item:not(.read), .notifications-list-item:not(.read) + .notifications-list-item {
  border-top: none;
}

.notifications-icon {
  position: relative;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
html[dir='rtl'] .notifications-icon {
  margin-right: 0;
  margin-left: 8px;
}
.notifications-message {
  padding-bottom: 5px;
  font-size: 14px;
  color: var(--muted-color);
  line-height: 18px;
}

.notifications-message b {
  font-family: var(--font-bold);
  color: var(--text-color);
}

.notifications-date {
  padding-top: 4px;
  font-size: 14px;
  color: var(--muted-color);
}

.notifications-list-item .btn {
  max-width: 160px;
  height: 32px;

  min-height: 32px;
  font-size: 14px;
  margin-top: 16px;
}

.notifications-item-trash {
  margin-left: 16px;
  pointer-events: none;
  transition: all .3s ease;
  opacity: 0;
}

.notifications-list-item:hover .notifications-item-trash {
  opacity: 1;
  pointer-events: auto;
}

.notifications-clear {
  height: 32px !important;
  min-height: 32px !important;
  max-height: 32px !important;
  padding: 0 16px !important;
  min-width: auto !important;
  white-space: nowrap;
  width: auto !important;
  border-radius: 20px !important;
}

.notifications-list-item .btn {
  color: var(--text-color) !important;
  /*background-color: var(--welcome-bg-color);*/
  /*color: var(--text-color);*/
}

.notifications-list-item:not(.read) .btn {
  background-color: #fff !important;
}

.notifications-show-more {
  cursor: pointer;
  font-family: var(--font-medium);
  border-top: 1px solid var(--border-color);
  transition: all .3s ease;
  margin-top: 16px;
  padding-top: 16px;
}

.notification-hidden .page-right-hidden-content {
  padding-bottom: 0;
}

.notification-mark-read-as {
  cursor: pointer;
  color: var(--muted-color);
}

.notification-mark-read-as:after {
  content: '';
  position: absolute;
  top: 0;
  left: 32px;
  height: 1px;
  width: calc(100% - 54px);
  background-color: var(--border-color);
}
html[dir='rtl'] .notification-mark-read-as:after {
  left: auto;
  right: 32px;
}
.notification-footer {
  z-index: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.notifications-footer-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-icon .big-star svg {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 992px) {
  .notifications-item-trash {
    opacity: 1;
    pointer-events: auto;
  }
}
