.profile-wrap {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.profile-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  padding-bottom: 40px;
}
.profile-avatar {
  width: 120px;
  min-width: 120px;
  height: 120px;
  font-size: 40px;
}
.profile-info {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding-top: 24px;
  transition: all .3s ease;
  transform: translateX(0);
}
.profile-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding-top: 24px;
  transition: all .3s ease;
  transform: translateX(100vw);
  max-width: 500px;
  margin: 0 auto;
}
.profile-edit .profile-form {
  transform: translateX(0);
}
.profile-edit .profile-info {
  transform: translateX(-100vw);
}
.profile-form .form-input-wrap {
  margin-bottom: 16px;
}

