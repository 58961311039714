.material-type {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
}

.material-type path {
  stroke: var(--muted-color);
}
.material-type-title {
  color: var(--muted-color);
  font-size: 12px;
}

