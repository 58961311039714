.spinner {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  pointer-events: none;
}

.loader,
.loader:before,
.loader:after {
  background: var(--text-color);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: var(--text-color);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 5px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.spinner.small {
  height: 16px;
  padding-top: 2px;
}
.spinner.small .loader {
  font-size: 2px;
}
.loader.white,
.loader.white:before,
.loader.white:after {
  color: #fff;
  background: #fff;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
